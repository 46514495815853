import { CommonService } from '../../shared/common.service';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { Md5 } from 'ts-md5';
import { Router, RouterModule } from '@angular/router';
import { Dialog } from '@angular/cdk/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-pwd',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule,TranslateModule],
  templateUrl: './update-pwd.component.html',
  styleUrls: ['./update-pwd.component.scss']
})
export class UpdatePwdComponent implements OnInit{
  public manageForm:any= FormGroup;
  public inintialValue:any={};
  public form_validation:any='';
  public userDetails:any={};
  public updateList:any={};
  public updateMessg:any='';
public formSubmited:any=false;
public dialogData: any=[];
@Input() set user_details(data: any) {
this.userDetails=data;
}
@Input() set update_msg(data: any) {
  this.updateList=data
  if (this.updateList.Message==1){
    this.updateMessg= "Default password needs to be changed"
  }
  else if (this.updateList.Message==2){
    this.updateMessg= "Password expired please update"
  }
  else{
    this.updateMessg="Password is not strong enough,so please update"
  }
}
@Output() formCloseEvent = new EventEmitter<any>();
  constructor(
    public cs: CommonService,
    public fb: FormBuilder,
    public router: Router,@Inject(PLATFORM_ID) private platformId: Object,

  ) {
  this.manageForm = this.fb.group({
    account_pwd: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.cs.updatePasswordPattern),Validators.maxLength(20) ]],
    confirm_pwd: ['', [Validators.required]],
  });
  this.inintialValue = this.manageForm.value;
}
CloseForm(action: any) {
  this.formCloseEvent.emit({ type: action });
}
public async onSubmit(form:any) {
  this.formSubmited=true;
if (form.valid) {

this.form_validation = "";

if(this.manageForm.value.account_pwd !==  this.manageForm.value.confirm_pwd){
  this.form_validation="Passwords do not match";
}
else{
  let userId = this.userDetails.user_session ? this.userDetails.user_session.user_id : this.cs.userSession.user_id;
  let orgId = this.userDetails.user_session ? this.userDetails.user_session.org_id : this.cs.userSession.org_id;
  let result: any = await this.cs.userPwdReset(this.updateList.Id, userId, this.manageForm.value['account_pwd'], orgId)
     if(result.status == 1){
      this.cs.openSnackBar("Password Updated Successfully");
      try {

        this.cs.userSession=this.userDetails.user_session;
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(
            "smarte2e_smato",
            JSON.stringify(this.cs.userSession)
          );
        }
        this.cs.userIdle.startWatching();
       // Start watching when user idle is starting.
        this.cs.userIdle.onTimerStart().subscribe((count: any) =>{
        }
       );
      // Start watch when time is up.
        this.cs.userIdle.onTimeout().subscribe(() => {
          this.cs.userIdle.stopTimer();
          this.cs.logout();
        });
          this.router.navigate(["/dash"]);


      } catch (error) {
        if(this.userDetails.mesg!==1)
        {
        this.cs.userSession['account_img'] = '';
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(
            "smarte2e_smato",
            JSON.stringify(this.cs.userSession)
          );
        }
        this.router.navigate(["/dash"]);
      }
      }

      if(this.userDetails.mesg==1){
        this.CloseForm(1);
        // this.manageForm.resetForm();
      }
     }
     else{
      this.form_validation="Invalid Password";
     }
}
} else {
this.form_validation = "Fill out All mandatory Fields";
}
}
ngOnInit(): void {
             //if(this.manageForm) this.manageForm.resetForm();

}
}
